@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
}

html, body, #root {
  height: 100%;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: RobotoRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}