.e-header-cell-label {
  font-size: 11px !important;
  color: #85858A !important;
  text-align: center !important;
}

.e-gantt.e-gantt-splitter {
  border-radius: 8px;
  border: none !important;
}

/*removes child task indentions*/
.e-icons.e-none {
  display: none !important;
}

.e-gantt .e-gantt-toolbar {
  border-color: transparent;
}

.e-gantt .e-gantt-chart .e-timeline-header-container {
  border-right-color: transparent;
}

.e-gantt .e-gantt-splitter {
  border-color: transparent;
  border-radius: 0px;
  border-width: 0px;
}